import React from "react";
import LTDesktopLoginForm from "./LTDesktopLoginForm";

export default function LTDesktopLogin({
  onChange,
  formFields,
  onBackClick,
  onButtonClick,
}) {
  return (
    <div className="flex flex-row xs:hidden">
      <div className="sm:w-1/2 custom1:w-full lg:w-3/5 bg-white">
        <LTDesktopLoginForm
          onChange={onChange}
          formFields={formFields}
          onBackClick={onBackClick}
          onButtonClick={onButtonClick}
        />
      </div>
      <div className="bg-Body flex flex-col justify-center items-center custom1:hidden sm:w-1/2 lg:w-2/5">
        {process.env.NEXT_PUBLIC_THEME === "LT" ? (
          <>
            <img src={"/Images/bigTruck.webp"} />
            <div className="flex items-center">
              <img
                src="/Images/logo-circle.webp"
                width={"60px"}
                height={"60px"}
                className="rounded-full"
              />
              <img
                src="/Images/wordmark-luckytruck.webp"
                style={{ height: "45px" }}
              />
            </div>
          </>
        ) : (
          <>
            <img src={"/Images/flexport/bigTruck.webp"} />
            <img width={"300px"} src="/Images/flexport/Logo-flexport.webp" />
          </>
        )}
      </div>
    </div>
  );
}
