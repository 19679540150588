import React, { useState, useEffect } from "react";
import axios from "axios";
import mixpanel from "mixpanel-browser";
import { useRouter } from "next/router";
import Loader from "../../components/Loading";
import { useMutation } from "@apollo/client";
import PageL1 from "../../components/Layout2Page";
import { useDispatch, useSelector } from "react-redux";
import LTMobileLogin from "../../components/LTMobileLogin";
import LTDesktopLogin from "../../components/LTDesktopLogin";
import { LT_LOGIN_MUTATION } from "../../pages/api/mutations";
import { setLoader } from "../../Redux/Actions/loadingAction";
import { setLTUser } from "../../Redux/Actions/ltUserActions";

const BrokerLogin = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  const [ip, setIP] = useState("");
  const { loading } = useSelector((state) => state.auth);
  const [login] = useMutation(LT_LOGIN_MUTATION, {
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
    dispatch(setLoader(false));
  }, []);

  const [formFields, setFormFields] = useState({
    email: { value: "", error: false, message: "" },
    password: { value: "", error: false, message: "" },
    disabled: false,
    mainError: false,
    mainErrorMessage: "",
    errors: false,
  });

  const onChange = ({ target: { name, value } }) => {
    var someProperty = { ...formFields };
    someProperty.mainError = false;
    someProperty.mainErrorMessage = false;
    someProperty.errors = false;
    someProperty.disabled = false;
    someProperty[name].value = value;
    if (someProperty[name].error === true) {
      someProperty[name].error = false;
      someProperty[name].message = "";
    }
    setFormFields(someProperty);
  };

  const validators = async () => {
    var someProperty = { ...formFields };
    if (someProperty.email.value === "") {
      someProperty.email.error = true;
      someProperty.email.message = "Email is required.";
      someProperty.errors = true;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        someProperty.email.value.trim()
      )
    ) {
      someProperty.email.error = true;
      someProperty.email.message =
        "Invalid email address. E.g. example@email.com.";
      someProperty.errors = true;
    }

    if (someProperty.password.value === "") {
      someProperty.password.error = true;
      someProperty.password.message = "Password is required.";
      someProperty.errors = true;
    }

    if (someProperty.errors) {
      someProperty.disabled = true;
    }
    setFormFields(someProperty);
    return someProperty.errors ? true : false;
  };

  const onButtonClick = async () => {
    let test = await validators();
    if (!test) {
      dispatch(setLoader(true));
      let someProperty = { ...formFields };
      someProperty.disabled = true;
      setFormFields(someProperty);
      try {
        localStorage.clear();
        sessionStorage.clear();
        let res = await login({
          variables: {
            email: formFields.email.value.trim(),
            password: formFields.password.value,
            org: process.env.NEXT_PUBLIC_ORGANIZATION,
          },
        });
        if (res.data.ltTeamLogin) {
          let someProperty = { ...formFields };
          Promise.all([
            dispatch(setLoader(false)),
            dispatch(setLTUser(res.data.ltTeamLogin.user)),
          ]);
          someProperty.disabled = false;
          setFormFields(someProperty);
          mixpanel.identify(formFields.email.value.trim());
          mixpanel.people.set({
            name: `${res.data.ltTeamLogin.user.name}`,
            type: "LT User",
            phone: "",
            IP: ip,
            $email: formFields.email.value.trim(),
          });
          localStorage.setItem("LTtoken", res.data.ltTeamLogin.token);
          router.push(process.env.NEXT_PUBLIC_LT_COMPANIES);
        } else {
          dispatch(setLoader(false));
          // ON ERRORS
          // localStorage.setItem("mainToken", resp.data.login.token);
          // dispatch(setLoader(false));
          if (
            res.errors[0].message.includes(
              "Please make sure to provide valid database credentials"
            )
          ) {
            let someProperty = { ...formFields };
            someProperty.mainError = true;
            someProperty.mainErrorMessage = `Invalid Credentials. Please make sure the email/password is correct.`;
            someProperty.disabled = true;
            setFormFields(someProperty);
          } else {
            let someProperty = { ...formFields };
            someProperty.mainError = true;
            someProperty.mainErrorMessage = `The account or password you’ve entered is incorrect. Try again.`;
            someProperty.disabled = true;
            dispatch(setLoader(false));
            setFormFields(someProperty);
          }
        }
      } catch (error) {
        console.log("error", error);
        dispatch(setLoader(false));
      }
    }
  };

  const onBackClick = () => {
    if (typeof window !== "undefined") {
      let getUrl = window.location;
      let url = getUrl.protocol + "//" + getUrl.host;

      window.open(url, "_self", "noopener,noreferrer");
    }
  };

  return (
    <PageL1
      title={
        process.env.NEXT_PUBLIC_THEME === "LT"
          ? "Trucking Insurance Provider | LuckyTruck"
          : "Trucking Insurance Provider | Flexport"
      }
    >
      <LTDesktopLogin
        onChange={onChange}
        formFields={formFields}
        onBackClick={onBackClick}
        onButtonClick={onButtonClick}
      />
      <LTMobileLogin
        onChange={onChange}
        formFields={formFields}
        onBackClick={onBackClick}
        onButtonClick={onButtonClick}
      />
      {loading && <Loader />}
    </PageL1>
  );
};

export default BrokerLogin;
