import React, { useEffect, useState } from "react";
import Icon from "../../Icon";
import Button from "../../Button";
import { useRouter } from "next/router";
import InputField from "../../InputField";
import { useMediaQuery } from "react-responsive";
import PasswordField from "../../PasswordField";

const LTDesktopLoginForm = ({ onChange, formFields, onBackClick, onButtonClick }) => {
  const router = useRouter();
  const isDesktopOrLaptop = useMediaQuery({
    minWidth: "1224px",
    maxWidth: "1823px",
  });

  const isBigScreen = useMediaQuery({ minWidth: "1824px" });
  const isTablet = useMediaQuery({ minWidth: "481px", maxWidth: "1223px" });

  const [isBig, setIsBig] = useState(null);
  const [isTab, setIsTab] = useState(null);
  const [isDesk, setIsDesk] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsTab(isTablet);
      setIsBig(isBigScreen);
      setIsDesk(isDesktopOrLaptop);
    }
  }, [isBigScreen, isTablet, isDesktopOrLaptop]);

  return (
    <div
      className={`flex flex-col gap-y-3 min-h-screen pt-20 ${
        isBig ? "px-60" : isDesk ? "px-32" : isTab ? "px-10" : "px-5"
      }`}
    >
      {!process.env.NEXT_PUBLIC_ORGANIZATION === "Stax" ? (
        <div className="flex gap-4 cursor-pointer" onClick={onBackClick}>
          <Icon height={20} name="icon-backarrow" />
          <h3 className="text-blue5 -mt-0.5">Back to site</h3>
        </div>
      ) : null}

      <h3 className="text-gray3 font-SpaceBold xs:text-lg sm:text-xl xl:text-4xl pt-20">
        Welcome back <span className="text-bgActiveButton">Team!</span>
      </h3>
      <h3 className="text-lg font-interMedium text-bgInactiveButton">
        Sign in to support your customers! (Go Luckytruck!🤘🏻)
      </h3>
      <div className="border-t-2 border-dividerColor opacity-50 mt-3 w-full mb-5" />

      <InputField
        width={"w-full"}
        height={"h-14"}
        placeholder="Email"
        onInputChange={onChange}
        value={formFields.email.value}
        error={formFields.email.error}
        message={formFields.email.message}
        name={"email"}
      />
      <PasswordField
        width={"w-full"}
        height={"h-14"}
        onInputChange={onChange}
        value={formFields.password.value}
        error={formFields.password.error}
        message={formFields.password.message}
      />
      <div className="mt-4">
        <Button
          title="CONTINUE"
          width="w-full"
          height="h-14"
          onButtonClick={onButtonClick}
          disabled={formFields.disabled}
        />
      </div>
      {formFields.mainError ? <h5 className="errorMessage">{formFields.mainErrorMessage}</h5> : null}
      <h3
        className="text-center mt-4 font-interMedium text-base text-createAcc cursor-pointer"
        onClick={() => router.push(process.env.NEXT_PUBLIC_LT_TEAM_FORGET_PASWORD)}
      >
        Forgot your password?
      </h3>
    </div>
  );
};

export default LTDesktopLoginForm;
